import React from "react"

import styles from "../../styles/main.module.css"

export default function BooksJan2021() {

  // format is 
  // (Recommend/Don't Recommend/Might Recommend -> 1, -1, 0), title, author, description/comments
  const finished = [
    // started and finished
    [0, "Zero To One", "Peter Thiel", "A decent book with solid case studies. Not particularly argumentitive, which leaves an intellectual mind yearning for reasons. Instead, it's more focused on what appears to be a highly practical world-view. Good lessons can be derived from it, but I would caution anyone not already about to pursue entrepeneurship or a related endeavour to read it, since you'll just forget it, and then it won't be useful anymore. Moreover, you might be able to learn the same core ideas by watching Y Combinator's 'How to Start a Startup' course and similar materials."],
    [1, "The Elegant Universe", "Brian Greene", "A fun read on String Theory. It may be pop-science but it's at least not totally useless, since the chapters on classical (and modern) physics in the beginning of the book have compelling thought experiments."],
    [0, "In Search of The Multiverse", "John Gribbin", "An exploration of all the different flavors of 'multiverse' which are believed to possibly exist by theoretical physicists. If you are more practically-minded, or prefer equations and falsifiability, you may be a little offput by this. Some chapters, though, like the early ones and the one on blackhole universes, are really cool."],
    [1, "How to Die in Space", "Paul M. Sutter", "A fun and broad guide on all the dangers of outer space. A good kicking off point to learn more about astronomy. I might like to read Carl Sagan's Cosmos or something similar in the future (a friend of mine recomended it)."],
    [1, "Deep Work", "Cal Newport", "An excellent practical guide to improve your meta-game (let's call it) regarding focus and quality work."],
    [1, "Of Course!  The Greatest Collection of Riddles and Brainteasers For Expanding Your Mind", "Zack Guido", "For some reason I decided to get good at brain teasers. Maybe, because I'd failed so many interviews, who knows. Anyways, this book in particular was fun, since the brain teasers were both not primarily linguistic, and hard (at least the later ones). I recommend this to someone looking for puzzles."],
    [1, "The Black Swan", "Nassim Nicholas Taleb", "I loved this. It covers not only epistimologic concepts but interesting sparks regarding how other beings might also have skewed visions of the world (including AI). Moreover, Nassim Taleb does an excellent job of telling fun stories and mocking dislikable characters in comedic fashion."],
    [0, "Skin in The Game", "Nassim Nicholas Taleb", "An interesting book with great core ideas, but they feel rushed. I wish Nassim Taleb had explored in more detail rather than glossing over many, many examples. The schizofrenic pace with a lot of insults may put off some readers."],
    [0, "Fooled By Randomness", "Nassim Nicholas Taleb", "I found this to be less exciting than the Black Swan. I recommend reading The Black Swan and Thinking Fast and Slow instead. That said, Nassim Taleb always manages to sneak in fun stories."],
    [1, "Gödel Escher Bach", "Douglas R. Hofstadter", "At first I thought this might be one of the best books I'd ever read. Now I'm a little less enchanted, but I do recommend part I plus the beginning and end of part II to anyone interested in minds, machines, AI, and/or formal systems and mathematics. Hofstadter does a wonderful job of combining parables, abstract ideas, mathematical puzzles, explorations of the mind, and aesthetics into one eternal golden braid. I was bored during the AI parts (it is dated), and to some extent, by some of the formalisms, but overall I enjoyed it immensely."],
]

const started = [
    // started but did not finish (these are the notable ones)
    [1, "Proofs from The Book", "Martin Aigler and Gunter M. Zingler", "This is basically mathematical poetry. Proofs from The Book presents various elegant proofs with guidance and some historical basis. It's a pleasure to read when you want to see elegant math."],
    [1, "The Archetypes and the Collective Unconscious", "C. G. Jung", "This was really interesting. Jung's notion of the archetypes goes a lot deeper than the fairy-tale bullshit I'd imagined. I intend to continue with this book in the near future. With that said, it's super dense, and there are a lot of layers which I will miss. Nonetheless, I think the core ideas can be drawn out without a ton of prior context."],
    [1, "Deep Learning", "Ian Goodfellow, Yoshua Bengio, and Aaron Courville", "This is a great book on deep learning theory. It is extremely attainble, though you may have to review some notational and mathematical preliminaries. I intend to finish it in the near future."],
    [1, "Probability Theory: A Concise Course", "Y. A. Rozanov","These dover books on mathematics are actually pretty great. They are accessable to anyone with some patience and a vague conceptual framework from high school or college. This probability book is primarily practical, providing the reader with some good practice problems every chapter. I recommend it. I intend to finish it in the near future."],
    [0, "Coming Back to Life", "Joanna Stacy and Molly Brown","Not entirely sure how I got to this title. It's a wierd combination of a practical guide for certain types of group meditation with both useful and epistemically questionable philosophical baggage. I stopped halfway through since I was not going to engage in group-meditation. I found the general themes to be compelling, and the idea of taking up a holonic view of the world was revealing (though in hindsight it seems obvious)."],
    [0, "Hands on Network Programming with C", "Lewis Van Winkle","I picked this up hoping to learn more about networking with C. I honestly have not coded in C since senior year of high school, and I thought this would provide me with a good way to pick it up again. It's not a bad book, but it will not prove useful unless you already are comfortable writing C and understand high-level concepts in networking. I'd recommend this to someone who has those two elements as a base and wants to link them together through implementation (it has a lot of great examples and exercises you can do, but explains close to nothing). I quit because I don't think I have time to focus on this yet. I'll probably focus on some basic C stuff before and then come back to it."],
    [0, "Introduction to Linear Algebra", "Gilbert Strang","This is the prototypical Linear Algebra textbook. I'd already taken 18.06 (MIT's linear algebra class), but wanted a refresher. To be honest, I'm not particularly keep on Strang's book. It does a good job example-wise but I'd like to see something a little more abstract and/or proof-based. I'd recommend it to someone who has never done linear algebra before and/or wants a lot of computational practice. I stopped this, because I honestly got bored of going through tons of problems. Since this book is not strong theory-wise (let's say) I didn't think to continue it if I wasn't going to be doing most (if not all) the problems."],
]

  // ternary confuses it not sure why
  const items_switch = (num, items) => {
    if (num > 0) {
      return items[0] // "8fc93a" // Recommend
    }
    else if (num < 0) {
      return items[1] // "dc493a" // Don't Recommend
    }
    else {
      return items[2] // "4392f1" // Might Recomend
    }
  }

  const recommend = (num) => {
    const color_items = [styles.green_text, styles.red_text, styles.blue_text]
    const recommend_items = ["Recommend", "Don't Recommend", "Might Recommend"]
    return (
        <span
          className={items_switch(num, color_items)}
        >
          {items_switch(num, recommend_items)}
        </span>
    )
  }

  return (
    <React.Fragment>
      <h2>Book Recommendations</h2>
      <hr className={styles.top_seperator} />
      <p>I've been reading more over the pandemic and I thought I'd leave a small set of reviews on what I've read.</p>
      <ul>
        {finished.map(bookParams => (
          <li>
            <h3>
              {bookParams[1]} by {bookParams[2]}
            </h3>
            {recommend(bookParams[0])}
            <p>{bookParams[3]}</p>
          </li>
        ))}
      </ul>
      <p>There are also various texts I've started and not finished (throughout the year). Here are some notable ones and what I thought.</p>
      <ul>
        {started.map(bookParams => (
          <li>
            <h3>
              {bookParams[1]} by {bookParams[2]}
            </h3>
            {recommend(bookParams[0])}
            <p>{bookParams[3]}</p>
          </li>
        ))}
      </ul>
      <div className={styles.phone_home}>
        <a href="/">Click me to return home.</a>
      </div>
    </React.Fragment>
  )
}
